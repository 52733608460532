@import '@/styles/variables';
@import '@/styles/mixins';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-index-header;

  &_loggedUserLinks {
    display: none;
  }
}

.header_margin {
  height: 80px;

  &__showAppBanner {
    height: 150px;
  }

  &__isListingsListPage {
    height: 102px;
  }

  &__isListingsListPage.header_margin__showAppBanner {
    height: 172px;
  }
}

.main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: $spacing-3;
  padding: $spacing-3 $spacing-4;
  background-color: #fff;
  box-shadow: $shadow;
}

.logo {
  display: block;
  margin-right: auto;
  line-height: 0;

  &ShiftRight {
    margin-right: auto;
  }
}

.search {
  display: none;
  width: 100%;
  margin-right: auto;
  order: 1;

  &__isListingsListPage {
    display: block;
  }
}

.app_download {
  display: none;
}

.login {
  display: none;
}

.top_banner {
  display: block;
}

[dir="rtl"] {
  .search {
    margin-right: 0;
    margin-left: auto;
  }
}

.language_picker,
.currency_picker {
  display: none;
}

@include for-screen-from-480 {
  .search {
    display: block;
  }
}

@include for-screen-from-768 {
  .language_picker,
  .currency_picker {
    display: block;
  }

  .header_margin {
    height: 68px;

    &__showAppBanner {
      height: 80px;
    }

    &__isListingsListPage.header_margin__showAppBanner {
      height: 80px;
    }
  }

  .top_banner {
    display: none;
  }

  .main {
    flex-wrap: nowrap;
  }

  .search {
    max-width: 460px;
    margin-top: 0;
    order: unset;
  }

  .logo {
    margin-right: 0;

    &ShiftRight {
      margin-right: auto;
    }
  }

  .menu {
    display: none;

    &__logined {
      display: block;
    }
  }

  .login {
    display: block;
    min-width: 130px;
  }
}

@include for-screen-from-1024 {
  .main {
    padding: $spacing-4 $spacing-6;
    gap: $spacing-5;
  }

  .app_download {
    display: block;
  }

  .header_margin {
    height: 80px;
  }
}

@include for-screen-from-1200 {
  .header {
    &_loggedUserLinks {
      display: block;
    }
  }
}
